import { AxiosError } from 'axios';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { ErrorResponse } from '../api/hscan';
import {
  HscanNotification,
  requestNotificationList,
  requestNotificationRead,
  requestNotificationReadById,
  requestNotificationServiceByIds,
  requestNotificationServiceList,
  requestUserNotificationService,
} from '../api/hscan/notification';
import { Page } from '../api/page';
import { StorageContext } from '../storageKey';

import { useUserState } from './accountHook';
import { MutationOption, QueryOption } from './react-query-type';

export const useNotificationList = (
  options?: QueryOption<Page<HscanNotification>>,
  page?: number,
  size?: number,
) => {
  const [user] = useUserState();

  const fetch = useCallback(async () => {
    const { data } = await requestNotificationList({ page, size });
    return data;
  }, [page, size]);
  return useQuery(
    ['notification', user?.userId, page, size] as QueryKey,
    fetch,
    options,
  );
};

export const useNotification = () => {
  const [user] = useUserState();

  const fetch = useCallback(async ({ pageParam = 0 }) => {
    const notificationResponse = await requestNotificationList({
      page: pageParam,
    });
    const notificationItems: HscanNotification[] =
      notificationResponse.data.content;
    return {
      content: notificationItems,
      last: notificationResponse.data.last,
      next: notificationResponse.data.pageable.pageNumber + 1,
    };
  }, []);
  return useInfiniteQuery(['notification', user?.userId], fetch, {
    getNextPageParam: lastPage =>
      lastPage.last ? undefined : lastPage.next + 1,
  });
};

export const useUnreadNotification = () => {
  const [newNoti, setNewNoti] = useState(false);
  const { data, isLoading } = useNotificationList({
    refetchOnWindowFocus: true,
  });

  const notificationList = useMemo(() => data?.content ?? [], [data]);
  useEffect(() => {
    if (notificationList.length && notificationList.find(n => !n.read)) {
      setNewNoti(true);
    } else {
      setNewNoti(false);
    }
  }, [notificationList, isLoading]);
  return newNoti;
};

export const useUpdateNotification = (
  options?: MutationOption<string, void, AxiosError<ErrorResponse>>,
) => {
  const client = useQueryClient();

  const update = useCallback(async (id: string) => {
    requestNotificationReadById(id);
  }, []);

  return useMutation(update, {
    ...options,
    onSuccess: (...params) => {
      client.invalidateQueries('notification');
      options?.onSuccess && options.onSuccess(...params);
    },
    onError: (...params) => {
      client.invalidateQueries('notification');
      options?.onError && options.onError(...params);
    },
  });
};

export const useReadAllNotifications = (
  options?: MutationOption<void, void, AxiosError<ErrorResponse>>,
) => {
  const client = useQueryClient();

  const update = useCallback(async () => {
    await requestNotificationRead();
  }, []);

  return useMutation(update, {
    ...options,
    onSuccess: (...params) => {
      client.invalidateQueries('notification');
      options?.onSuccess && options.onSuccess(...params);
    },
    onError: (...params) => {
      client.invalidateQueries('notification');
      options?.onError && options.onError(...params);
    },
  });
};

const NOTIFICATION_READ = 'NOTIFICATION_READ';

export const useNotificationRead = () => {
  const { mutate: read } = useReadAllNotifications();
  const storage = useContext(StorageContext);

  const setNotiItem = useCallback(async () => {
    const notiItem = await storage.getItem(NOTIFICATION_READ);
    if (!notiItem) {
      localStorage.setItem(NOTIFICATION_READ, 'true');
    }
  }, [storage]);

  useEffect(() => {
    setNotiItem();

    return () => {
      read();
      localStorage.removeItem(NOTIFICATION_READ);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/* noti read가 불완전하게 처리됐을 때를 대비하는 hook */
export const useCheckNotifications = () => {
  const { mutate: read } = useReadAllNotifications();
  const hasEnteredNotiPage = localStorage.getItem(NOTIFICATION_READ);
  useEffect(() => {
    if (hasEnteredNotiPage) {
      read();
      localStorage.removeItem(NOTIFICATION_READ);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useNotificationServiceList = () => {
  const fetch = useCallback(async () => {
    const { data } = await requestNotificationServiceList();
    return data;
  }, []);
  return useQuery(['NOTIFICATION_SERVICE_LIST'], fetch);
};

export const useUserNotificationServiceList = () => {
  const fetch = useCallback(async () => {
    const { data } = await requestUserNotificationService();
    return data;
  }, []);
  return useQuery(['USER_NOTIFICATION_SERVICE_LIST'], fetch);
};

export const useUpdateUserNotificationServiceList = (
  options?: MutationOption<string[], void>,
) => {
  const client = useQueryClient();

  const update = useCallback(async (serviceIds: string[]) => {
    await requestNotificationServiceByIds(serviceIds);
  }, []);

  return useMutation(update, {
    ...options,
    onSuccess: (...params) => {
      client.invalidateQueries('USER_NOTIFICATION_SERVICE_LIST');
      options?.onSuccess && options.onSuccess(...params);
    },
    onError: (...params) => {
      options?.onError && options.onError(...params);
    },
  });
};
