import axios, { AxiosResponse } from 'axios';

import { HSCAN_BACKEND_API_URL } from '../../env';
import { Page } from '../page';

import { PageQueryParams } from './types';

export interface SimpleNotification {
  id: string;
  type: 'SIMPLE';
  userId: string;
  createdAt: string;
  message: string;
  title: string;
  read: boolean;
}
export interface ImageOnSiteNotification {
  id: string;
  type: 'ONSITE';
  userId: string;
  createdAt: string;
  message: string;
  title: string;
  read: boolean;
  link: string;
  expiredAt: string;
  hospitalName: string;
}

export interface NotificationService {
  serviceId: string;
  available: boolean;
}

export interface UserNotificationService {
  serviceId: string;
}

export type HscanNotification = SimpleNotification | ImageOnSiteNotification;

export interface RequestDetail {
  relationship: {
    name: string; // HSCAN_CONTEXT
  };
}

export enum RequestStatus {
  pending = 'PENDING',
  accepted = 'ACCEPTED',
  declined = 'DECLINED',
}

const DEFAULT_NOTIFICATION_PAGE_SIZE = 10;

export type RequestNotificationListParams = PageQueryParams;
export const requestNotificationList = ({
  page,
  size = DEFAULT_NOTIFICATION_PAGE_SIZE,
}: RequestNotificationListParams) => {
  return axios.get<Page<HscanNotification>>(
    `${HSCAN_BACKEND_API_URL}/notification`,
    {
      params: { page, size },
    },
  );
};

export const requestNotificationReadById = (id: string) => {
  return axios.put<
    HscanNotification,
    AxiosResponse<HscanNotification>,
    undefined
  >(`${HSCAN_BACKEND_API_URL}/notification/${id}/read`, undefined);
};

export const requestNotificationRead = () => {
  return axios.put<unknown>(
    `${HSCAN_BACKEND_API_URL}/notification/read`,
    undefined,
  );
};

export const requestNotificationServiceList = () => {
  return axios.get<NotificationService[]>(
    `${HSCAN_BACKEND_API_URL}/notification/service/list`,
  );
};

export const requestNotificationServiceByIds = (serviceIds: string[]) => {
  return axios.put(`${HSCAN_BACKEND_API_URL}/notification/service`, {
    serviceIds,
  });
};

export const requestUserNotificationService = () => {
  return axios.get<UserNotificationService[]>(
    `${HSCAN_BACKEND_API_URL}/notification/service`,
  );
};
