import LanguageIcon from '@mui/icons-material/Language';
import {
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  selectClasses,
} from '@mui/material';
import i18n from 'i18next';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import { onChangeLocale } from '../../utils/onChangeLocale';

const useSupportedLanguages = () => {
  const resources = Object.keys(i18n.options.resources ?? {});
  return resources.length ? resources : i18n.languages;
};

// ?? 왜 100px로 제한한 거지
const StyledSelect = styled(MuiSelect)<{ $color?: string; $icon?: boolean }>`
  & {
    ${props => (props.$icon ? 'width: 24px;' : '')};
  } // 최대 길이 제한. 아이콘인 경우 24px 로 제한

  &:before,
  &:after {
    content: none !important;
  } // Select 의 focus 용 underline 제거

  & .${selectClasses.select} {
    padding-top: 7px;
    padding-bottom: 7px;
    ${props => props.$icon && 'padding: 0px !important;'}
    font-size: 14px;
    line-height: ${props => (props.$icon ? '0' : '24px')};
    color: ${props => props.$color ?? 'white'};
  }
`;

export const LOCAL_STORAGE_LOCALE_KEY = 'hscan-user-locale';

interface LanguageSelectorProps {
  color?: string;
  icon?: boolean;
}
export const LanguageSelector = ({
  color,
  icon = true,
}: LanguageSelectorProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'common.locale',
  });

  const languages = useSupportedLanguages();
  const current = i18n.language;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const newLanguage = event.target.value as 'ko' | 'en' | 'es' | 'id';
    if (languages.includes(newLanguage)) {
      localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, newLanguage);
      onChangeLocale(newLanguage);
    }
  };

  const renderIcon = useCallback(() => <LanguageIcon />, []);

  return (
    <StyledSelect
      value={current}
      onChange={handleChange}
      variant="standard"
      renderValue={icon ? renderIcon : undefined}
      IconComponent={icon ? () => null : undefined}
      $color={color}
      $icon={icon}>
      {languages.map(language => (
        <MenuItem key={language} value={language}>
          {t(language)}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
